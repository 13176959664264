import { defineStore } from "pinia";
import { getCurrentUser, signOut, fetchAuthSession } from "aws-amplify/auth";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { CookieStorage } from "aws-amplify/utils";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: null,
    loading: false,
    isModalExpirationTokenVisible: false,
    sessionId: null,
    typeDevice: null,
    durationInterval: null,
  }),
  actions: {
    async fetchUser() {
      this.loading = true;
      const maxRetries = 3; // Número máximo de intentos
      const delayBetweenRetries = 1000; // Milisegundos entre cada intento

      const cookieStorage = new CookieStorage({
        domain: ".carry.legal",
        secure: true,
        path: "/",
        sameSite: "lax",
        expires: 365,
      });

      cognitoUserPoolsTokenProvider.setKeyValueStorage(cookieStorage);

      let attempt = 0;

      while (attempt < maxRetries) {
        try {
          await fetchAuthSession({ forceRefresh: true });
          this.user = await getCurrentUser();
          return; // Si tiene éxito, salimos de la función
        } catch (error) {
          console.warn(
            `Intento ${attempt + 1} de ${maxRetries} fallido:`,
            error.message
          );

          if (
            error.message.includes("User needs to be authenticated") ||
            error.message.includes("Refresh Token has expired") ||
            error.message.includes("Access Token has been revoked") ||
            error.message.includes("Access Token has expired") ||
            error.message.includes("User is not authenticated")
          ) {
            if (attempt === maxRetries - 1) {
              console.error(
                "Máximo número de intentos alcanzado. Eliminando datos..."
              );

              // Eliminamos todas las cookies
              document.cookie.split(";").forEach((cookie) => {
                let nombre = cookie.split("=")[0].trim();
                document.cookie =
                  nombre + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              });

              // Limpiar almacenamiento
              localStorage.clear();
              sessionStorage.clear();

              // Borrar caché
              if ("caches" in window) {
                caches.keys().then((names) => {
                  names.forEach((name) => caches.delete(name));
                });
              }

              // Limpiar IndexedDB
              const databases = await indexedDB.databases();
              await Promise.all(
                databases.map((db) => indexedDB.deleteDatabase(db.name))
              );

              this.closePageBecauseUnauthenticated();
            }
          }
        } finally {
          attempt++;
          if (attempt < maxRetries) {
            await new Promise((resolve) =>
              setTimeout(resolve, delayBetweenRetries)
            );
          }
        }
      }

      this.loading = false;
    },

    async logout(inmediate = false) {
      const cookieStorage = new CookieStorage({
        domain: ".carry.legal",
        secure: true,
        path: "/",
        sameSite: "lax",
        expires: 365,
      });

      cognitoUserPoolsTokenProvider.setKeyValueStorage(cookieStorage);

      try {
        await signOut();

        // Eliminamos todas las cookies
        document.cookie.split(";").forEach((cookie) => {
          let nombre = cookie.split("=")[0].trim();
          document.cookie =
            nombre + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        });

        // Limpiar localStorage
        localStorage.clear();

        // Limpiar sessionStorage
        sessionStorage.clear();

        // Borrar caché
        if ("caches" in window) {
          caches.keys().then((names) => {
            names.forEach((name) => caches.delete(name));
          });
        }

        // Limpiar IndexedDB
        const databases = await indexedDB.databases();
        await Promise.all(
          databases.map((db) => indexedDB.deleteDatabase(db.name))
        );

        this.isModalExpirationTokenVisible = true;

        if (inmediate) {
          window.location.href = "https://carry.legal/iniciar-sesion/";
          return;
        }

        setTimeout(() => {
          window.location.href = "https://carry.legal/iniciar-sesion/";
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    },

    async refreshSession() {
      const cookieStorage = new CookieStorage({
        domain: ".carry.legal",
        secure: true,
        path: "/",
        sameSite: "lax",
        expires: 365,
      });

      cognitoUserPoolsTokenProvider.setKeyValueStorage(cookieStorage);

      try {
        await fetchAuthSession({ forceRefresh: true });
      } catch (error) {
        console.log(error);

        if (error.message.includes("Refresh Token has expired")) {
          console.warn("Sesión expirada. Redirigiendo a login...");
          this.logout();
        }
      }
    },

    closePageBecauseUnauthenticated() {
      this.isModalExpirationTokenVisible = true;

      setTimeout(() => {
        window.location.href = "https://carry.legal/iniciar-sesion/";
      }, 1000);
    },
  },

  getters: {
    isAuthenticated: (state) => !!state.user,
  },
});
