import { createApp } from "vue";
import { createPinia } from "pinia";
import { Amplify } from "aws-amplify";
import { CookieStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { Hub } from "aws-amplify/utils";
import { useAuthStore } from "@/stores/storeAuth";
import App from "./App.vue";
import router from "./router";
import useBack from "./helpers/methods/useBack";
import awsMobile from "./aws-exports";
import "../src/styles/main.scss";
import VCalendar from "v-calendar";
import "v-calendar/style.css";

Amplify.configure(awsMobile);

// Set cookies as storage for auth tokens
cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());

// Set the title of the app
document.title = "CARRY.";

// Create the app instance
const app = createApp(App);
app.use(createPinia());
app.use(router);

app.use(VCalendar, {});

useBack(router);

const authStore = useAuthStore();
Hub.listen("auth", ({ payload }) => {
  switch (payload.event) {
    case "tokenRefresh_failure":
      authStore.logout();
      break;
    case "tokenRefresh":
      authStore.fetchUser();
  }
});

app.mount("#app");
