<template>
  <main>
    <main-layout></main-layout>
  </main>
</template>

<script>
import { onMounted, onUnmounted } from "vue";
import { useUserStore } from "./stores/userStore";
import { useNotificationsStore } from "@/stores/notifications";
import { useAuthStore } from "@/stores/storeAuth";
import MainLayout from "./layouts/MainLayout.vue";
import useActivityTracking from "@/helpers/methods/useActivityTracking";
import Intercom from "@intercom/messenger-js-sdk";

export default {
  components: { MainLayout },

  setup() {
    const authStore = useAuthStore();
    const userStore = useUserStore();
    const notificationsStore = useNotificationsStore();
    const { isTracking } = useActivityTracking();
    let refreshInterval = null;

    Intercom({
      app_id: "ks2iwj5k",
    });

    onMounted(async () => {
      try {
        // await authStore.fetchUser();
        await userStore.loadUserAttributes();
        notificationsStore.fetchNotifications();

        if (!refreshInterval) {
          // Solo crea un `setInterval` si no existe
          refreshInterval = setInterval(() => {
            authStore.refreshSession();
          }, 55 * 60 * 1000); // Refresca cada 55 minutos
        }
      } catch (error) {
        console.error(error);
      }
    });

    onUnmounted(() => {
      if (refreshInterval) clearInterval(refreshInterval);
    });

    return {
      isTracking,
    };
  },
};
</script>

<style></style>
